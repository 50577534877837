/* INFO Style for react-datepicker */

.react-datepicker {
  font-family: 'Inter' sans-serif !important;
  font-size: 1rem !important;
  color: var(--form-input-color) !important;
  border: none !important;
  border-radius: 0.8rem !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
  left: 10px !important;
}

.react-datepicker__header {
  border-bottom: solid 5px var(--accent-color) !important;
  background: white !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 7px;
  background-color: white !important;
  color: var(--dark) !important;
  width: 30px;
  height: 30px;
}

.react-datepicker__day.react-datepicker__day--today.react-datepicker__day--selected {
  border: none;
  border-radius: 7px;
  background-color: var(--accent-color) !important;
  color: var(--white-color) !important;
}

.react-datepicker__day.react-datepicker__day--selected {
  border: none;
  border-radius: 7px !important;
  background-color: var(--accent-color) !important;
  color: white;
  font-weight: bold !important;
  width: 30px;
  height: 30px;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 7px !important;
  background-color: #ffffff !important;
  color: inherit !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
  border-radius: 7px !important;
  background-color: #f0f0f0 !important;
  color: inherit;
}

.react-datepicker__day--outside-month {
  color: var(--form-placeholder-color) !important;
}

.react-datepicker-popper .react-datepicker__navigation {
  padding-top: 12px !important;
  color: var(--accent-color) !important;
}

.react-datepicker__navigation-icon::before {
  border-color: var(--accent-color) !important;
  border-width: 2px 2px 0 0 !important;
  width: 12px !important;
  height: 12px !important;
}

.react-datepicker__current-month {
  font-size: 1rem !important;
  color: var(--dark) !important;
}

/* .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 7px !important;
    background-color: var(--accent-color) !important;
    color: var(--white-color) !important;
  } */
